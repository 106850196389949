@font-face {
  font-family: pixelFont;
  src: url(./font/slkscr.ttf);
}

@font-face {
  font-family: pixelFont2;
  src: url(./font/uni.ttf);
  
}

/* remove scrollbar */
body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

html, body {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  background-image: linear-gradient(to bottom, #000000, #320101,#372f00, #002f0f, #0d0035, #1d0034 );
  text-align: center;
  font-family: pixelFont;
  width: 100%;
  align-items: center;
  margin: auto;
}


.landing-head {
  text-align: center;
  font-family: pixelFont;
  width: 100%;
  align-items: center;
  margin: auto;
}

.landing-head-content-links {
  text-align: center;
  align-items: center;
  justify-items: center; 
}

.social-link {
  width: 45px;
  height: 45px;
  color: gainsboro;
  fill: gainsboro;
  stroke: gainsboro;
  margin: -5px 5px 25px 5px;
}

@media only screen and (max-width: 650px) {
  .social-link {
    width: 35px;
    height: 35px;
   
  }
}


.social-link:hover {
  cursor: pointer;
  /* increase size a little ease-in*/
  transform: scale(1.1);
}

.social-link2 {
  width: 45px;
  height: 45px;
  color: gainsboro;
  fill: gainsboro;
  stroke: gainsboro;
}

@media only screen and (max-width: 650px) {
  .social-link2 {
    width: 35px;
    height: 35px;
   
  }
}


.social-link2:hover {
  cursor: pointer;
  /* make fill a linear gradient */ 
  fill: darkgray;
}

.button-nonclick {
  /* width: 20%; */
  font-size: 150%;
  background-color: #212121;
  background-image: linear-gradient(to bottom right, #ff9770, #ffd670, #b5e8a4, #70d6ff);
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  /* Pointer on hover */
  cursor: pointer;
  font-family: pixelFont;
  animation: shake 0.5s infinite;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.button-nonclick:hover {
  cursor: default;
}

#citizen {
  width: 100%;
  height: 100%;
}

.citizen-container {
  width: 70%;
  height: 70%;
}

.citizen-icon {
  /* move up an down */
  /* animation: citizen-icon 1s linear infinite; */
  width: 300%;
  height: 300%;
  /* fit inside span */
  object-fit: contain;
  /* center */
  margin: auto;
  /* make span inline */
  /* center */  
}

.span-rainbow {
  /* animation: animate-gradient 1s linear infinite; */
  background: linear-gradient(to right, #ff9770, #ffd670, #b5e8a4, #70d6ff, #70d6ff, #b5e8a4, #ffd670, #ff9770);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
}

.span-red {
  /* animation: animate-gradient 1s linear infinite; */
  background: linear-gradient(to right, #ffbf2b, #fbce2d, #efbb00, #efa700, #efa700, #efbb00, #fbce2d, #ffbf2b);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* @keyframes animate-gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
} */

.App {
  background-image: linear-gradient(to bottom, #000000, #320101,#372f00, #002f0f, #0d0035, #1d0034 );
  text-align: center;
  font-family: pixelFont;
  width: 100%;
  align-items: center;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
    background-color: transparent;
    margin: auto;
    width: 50%;
    height: 5%;
    z-index: 100;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    transition: transform 0.5s ease;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5);
}

/* iPhone */
@media only screen and (max-width: 580px) {
  .navbar {
    width: 40%;
    height: 7%;
    /* center */
    text-align: center;
  }
}

/* iPad 568px to 768px */
@media only screen and (min-width: 580px) and (max-width: 769px) {
  .navbar {
    width: 80%;
    height: 7.5%;
    /* center */
    text-align: center;
    /* move to center horizontally */

  }
}

/* Small PC 768px to 900ox */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .navbar {
    width: 60%;
    height: 6%;
    /* center */
    text-align: center;

  }
}

.font-150 {
  font-size: 150%;
}

.nav-link {
  background-image: linear-gradient(to bottom right, #ff9770, #ffd670, #b5e8a4, #70d6ff);
    color: black;
    text-decoration: none;
    padding-right: 1%;
    font-size: x-large;
    background-color: rgba(255,255,255, 0.2);
    border: black 3px solid;
    border-radius: 10px;
    padding: 1%;
}

.nav-link:hover {
  border: 3px solid #ecebe8;
}

.landing-head {
    text-align: center;
    background-color: transparent;
    padding-top: 3%;
}

.landing-head-title {
  font-size: 200%;
}

@media only screen and (max-width: 650px) {
  .landing-head-title {
    font-size: 150%;
    margin-top: 13%;
  }
}


.landing-head-description {
  font-size: 75%;
}

#e-link {
  text-decoration: none;
  color: white;
}

#e-link:hover {
  cursor: pointer;
}

.landing-head-claim-link {
  text-decoration: none;
  color: white;
}

.landing-head-claim-link:hover {
  cursor: pointer;
}

.landing-body {
  /* Vertically aligned items with a gap */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* center */
  text-align: center;
  /* center */
  margin: auto;
  /* center */
  width: 100%;
  /* center */
  height: 100%;
  /* center */
  padding: 0;
  /* center */
  background-color: transparent;
  /* center */
  
  gap: calc(5vh + 5px) calc(5vw + 5px);


}

.landing-body-content {
    text-align: center;
    border: 1px solid rgb(125, 124, 124);
    color: white;
    /* Put a thin layer over background */
    /* background-color: rgba(175, 175, 183, 0.4); */
    border-radius: 25px;
    width: 75%;
    margin: auto;
    padding: 2%;
    /* shadow on all edges */
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 590px) and (max-width: 768px) {
  .landing-body-content-header-whitepaper {
    font-size: 150%;
  }
}

@media only screen and (max-width: 590px) {
  .landing-body-content-header-whitepaper {
    font-size: 125%;
  }
}


.landing-body-content-body {
  font-family: pixelFont;
  font-size: 75%;
}

.wallet-connect {
  width: 100%;
  height: 100%;
  margin: auto;
  /* center */
  text-align: center;
  /* center */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.button-connect-wallet {
  font-size: 150%;
  background-color: #212121;
  background-image: linear-gradient(to bottom right, #ff9770, #ffd670, #b5e8a4, #70d6ff);
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  /* Pointer on hover */
  cursor: pointer;
  font-family: pixelFont;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5);
}

.button-connect-wallet:hover {
  border: 2px solid #ecebe8;
  color:gray;
  /* Make the text inside the button shake */
  animation: none;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.button-claim-container {
  /* 3 elements in a row, no gap */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px;
  margin: auto;
  /* center */
  text-align: center;
  /* center */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

}

.button-claim {
  /* width: 20%; */
  font-size: 150%;
  background-color: #212121;
  background-image: linear-gradient(to bottom right, #ff9770, #ffd670, #b5e8a4, #70d6ff);
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  /* Pointer on hover */
  cursor: pointer;
  font-family: pixelFont;
  animation: shake 0.5s infinite;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.5);
}

/* Make buttons go slightly gray when hovered */
.button-claim:hover {
  border: 2px solid #ecebe8;
  color:gray;
  /* Make the text inside the button shake */
  animation: none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.claim-amount-container {
  /* 3 elements in a row, no gap, total width of all rows is 30% width of container */
  display: grid;
  grid-template-columns: 10% 10% 10%;
  grid-gap: 8px;
  margin: auto;
  /* center */
  text-align: center;
  /* center */
  justify-content: center;
  align-items: center;
  width: 100%;
}

.claim-amount {
  font-size: 150%;
  height: 90%;
  width: 80%;
  background-color: transparent;
  color: white;
  border-radius: 10px;
  /* Pointer on hover */
  cursor: pointer;
  font-family: pixelFont;
  /* center text */
  text-align: center;
  /* center text */
  justify-content: center;
  align-items: center;
  /* move to center */
  margin: auto;
}

input[type='number'] {
  -moz-appearance: textfield;/*For FireFox*/


}

@media only screen and (max-width: 650px) {
  .claim-amount {
    font-size: 100%;
    height: 60%;
  }
}

@media only screen and (max-width: 500px) {
  .claim-amount {
    font-size: 50%;
  }
}

a {
  color: #f6ff00;
}

a:hover {
  color: #b2b709;
  text-decoration: none;
}

.claim-amount-crement {
  /* width: 80%; */
  height: 100%;
  font-size: 150%;
  background-image: linear-gradient(to bottom right, #ff9770, #ffd670, #b5e8a4, #70d6ff);
  color: black;
  border-radius: 10px;
  margin: auto;
  /* Pointer on hover */
  cursor: pointer;
  font-family: pixelFont;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 750px) {
  .claim-amount-crement {
    font-size: 125%;
  }
}

@media only screen and (max-width: 650px) {
  .claim-amount-crement {
    font-size: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .claim-amount-crement {
    font-size: 50%;
  }
}

.claim-amount-crement:hover {
  border: 2px solid #ecebe8;
  color:gray;
  /* Make the text inside the button shake */
  animation: none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

/* Shake animation */
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.claiming-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  margin: auto;
  /* center */
  text-align: center;
  /* center */
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.claim-successful {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 100%;

  /* Make this a modal */
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.95);
}

.claim-successful-body {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  /* overflow hidden firefox */
  -moz-overflow:hidden;
  height: 100%;
}

@media only screen and (max-width: 750px) {
  .claim-successful-body {
    /* allow scrolling */
    overflow-y: scroll;
    -moz-overflow-y: hidden;

  }
}

@media only screen and (max-height: 575px) {
  .claim-successful-body {
    /* allow scrolling */
    overflow-y: scroll;

  }
}

canvas {
  position: fixed;
}

.button-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 50px;
  color: #ecebe8;
  cursor: pointer;
  background: none;
  border: none;

  font-family: pixelFont;
}

.button-close:hover {
  color: white;
}

.margin-top-10 {
  margin-top: 3%;
}

.width-75 {
  width: 75%;
  margin: auto;
}

.claiming-text .ellipsis {
  animation: ellipsis 1s linear infinite;
}

@keyframes ellipsis {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.total-claimed-text {
  margin-top: 20px;
}

@media only screen and (max-width: 750px) {
  .total-claimed-text {
    font-size: 75%;
  }
}

.claiming-text .ellipsis:nth-child(2) {
  animation-delay: 0.2s;
}

.claiming-text .ellipsis:nth-child(3) {
  animation-delay: 0.4s;
}

.claiming-text-txn-message {
  color: red;
}

.claimed-token-svgs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  width: 100%;
  height: 100%;
  margin: auto;
  /* translate to center */
  transform: translate(0, 0);
  gap: 20px;

}

@media only screen and (max-width: 750px) {
  .claimed-token-svgs {
    font-size: x-large;
    /* 2 items in a row */
    flex-wrap: wrap;
  }
}



.claimed-token-svg {
  position: relative;
  border-radius: 10px;
  border: 2px solid rgb(125, 124, 124);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.5);
  padding-top: 20px;
  color: #ecebe8;
  width: 100%;
  /* center */
  text-align: center;
  /* center */
  justify-content: center;
  align-items: center;
  /* center */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.claimed-token-svg:hover {
  border: 2px solid white;
  color:white;
  /* Make the text inside the button shake */
  animation: none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.view-on-x2y2 {
  /* modal that takes dimensions of its parent */
  position: absolute;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* center text vertically */
  display: flex;
  flex-direction: column;
  z-index: 50;
  left: 0;
  right: 0;
  width: 100%;
  height: 97%;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.95);
  /* fade in */
  animation: fade-in 0.2s;
  /* translate up 2 px */
  transform: translateY(-5px);
}


.button-stop-confetti {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #ecebe8;
  cursor: pointer;
  background: none;
  border: 1px solid #ecebe8;

  font-family: pixelFont;
}

.share-twitter-modal {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  transform: translateY(-100%);
  opacity: 0;
  z-index: 25000;
}

.share-twitter-modal.is-visible {
  transform: translateY(0);
  opacity: 1;
}

.button-stop-confetti:hover {
  color: white;
  border: 1px solid white;
}

.account {
  width: 100%;
}

.connected-account-container {
  width: 50%;
  margin: auto;
}

.connected-account-container:hover {
  color:gray;
  /* Make the text inside the button shake */
  animation: shake;
  cursor: pointer;
}

.connected-account-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
}

.connected-account-tagline {
  font-size: medium;
}

.modal:hover {
  cursor: pointer;
}

.carousel-container {
  /* width: 100%;
  height: 100%; */
}

.carousel {
  /* width: 100%;
  height: 100%; */
}

.marquee-container {
  /* width: 100%;
  height: 100%; */
}

.marquee {
  /* width: 100%;
  height: 100%; */
}

div:has(> .carousel-item) {
  width: 250%;
  /* height: 100%; */
}

@media (max-width: 768px) {
  div:has(> .carousel-item) {
    width: 300%;
    /* height: 100%; */
  }
}

@media (max-width: 500px) {
  div:has(> .carousel-item) {
    width: 400%;
    /* height: 100%; */
  }
}

.carousel-item {
  width: 100%;
  height: 100%;
  margin-right: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  cursor: default;
}

/* Firefox */
input[type=number] {
  /* No decoration or hover */
  -moz-appearance: none;
  cursor: default;
}

.faq-section-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.faq-section-body-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.faq-section-body-content-question {
  background-image: linear-gradient(to bottom right, #ff9770, #ffd670, #b5e8a4, #70d6ff);
  color: black;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.5);
  font-size: 100%;
  font-family: pixelFont;
  margin: 0;
  padding: 5px;
}

.faq-section-body-content-question:hover {
  cursor: pointer;
}

.faq-section-body-question-content-answer {
  font-size: 75%;
  font-family: pixelFont;
  margin: 5px;
  padding: 0;
}

.faq-section-body-content-answer {
  /* make sure span is in-line with p */
  display: inline;

}

.faq-section-body-content-answer-link {
  /* Link color */
  color: #f6ff00;
}

.landing-body-content-body-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 30%;
  height: 100%;
  gap: 5px;
  border: 2px solid rgb(125, 124, 124);
  border-radius: 20px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: white;
  text-decoration: none;

}
.landing-body-content-body-team:hover {
  cursor: pointer;
  border: 2px solid white;
  box-shadow: 5px 5px 5px px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  width: 32%;
}

@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .landing-body-content-body-team {
    width: 45%;
  }
  .landing-body-content-body-team:hover {
    width: 47%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .landing-body-content-body-team {
    width: 55%;
  }
  .landing-body-content-body-team:hover {
    width: 57%;
  }
}

@media only screen and (min-width: 540px) and (max-width: 600px) {
  .landing-body-content-body-team {
    width: 60%;
  }
  .landing-body-content-body-team:hover {
    width: 62%;
  }
}

@media only screen and (max-width: 540px) {
  .landing-body-content-body-team {
    width: 80%;
  }
  .landing-body-content-body-team:hover {
    width: 82%;
  }
}

.landing-body-content-body-team-image {
  /* make image way smaller */
  width: 150px;
  height: 150px;
  /* rounded corners */
  border-radius: 50%;
}

@media only screen and (max-width: 800px) {
  .landing-body-content-body-team-image {
    width: 100px;
    height: 100px;
  }
}

.landing-body-content-body-team-name {
  font-size: 150%;
  font-family: pixelFont;
  text-decoration: none;
  color: white;
}

.landing-body-content-body-team-bio {
  font-size: 75%;
  width: 100%;
  margin: auto;
  text-decoration: none;
  color: white;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }