@font-face {
  font-family: pixelFont;
  src: url(./font/slkscr.ttf);
}

body {
  font-family: pixelFont;
  /* background: linear-gradient(to bottom right, #f27a7d, #ff9770, #ffd670, #b5e8a4, #70d6ff, #ceb3f2); */
  background-color: #212121;
  background-size: 300% 300%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ecebe8;
  font-size: xx-large;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
